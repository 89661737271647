import CustomIcon from '@components/custom-icon/CustomIcon';
import { CommentIcon } from '@components/custom-icon/icons/CommentIcon';
import useDialogContext from '@hooks/useDialogContext';
import { IRequest } from '@shared/interfaces/IRequest';

interface CommentIconButtonProps {
  request: IRequest;
}

const CommentIconButton = ({ request }: CommentIconButtonProps): JSX.Element => {
  const { openDialog } = useDialogContext();

  const showCommentDialog = (): void => {
    openDialog('comment-dialog', { comment: request.request_description ?? '' });
  };

  return (
    <div>
      <CustomIcon
        icon={CommentIcon}
        className='cursor-pointer flex justify-content-center align-items-center'
        hoverColor='var(--primary-color)'
        onClick={(): void => showCommentDialog()}
      />
    </div>
  );
};

export default CommentIconButton;
