import Header from '@components/header/Header';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): JSX.Element => (
  <div>
    <Header />
    <div className='md:p-5 p-3'>{children}</div>
  </div>
);

export default Layout;
