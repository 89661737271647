import { RouterPath } from '@config/router';
import { classNames } from 'primereact/utils';

import MobileHeaderLink from '../mobile-header-link/MobileHeaderLink';

const MobileMenuOverlay = (): JSX.Element => (
  <div className={classNames('h-full flex flex-column')}>
    <MobileHeaderLink path={RouterPath.COMPANIES} label='Companies' />
    <MobileHeaderLink path={RouterPath.USERS} label='Users' />
    <MobileHeaderLink path={RouterPath.PRODUCTS} label='Products' />
    <MobileHeaderLink path={RouterPath.REQUESTS} label='Requests' />
  </div>
);

export default MobileMenuOverlay;
