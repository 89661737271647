import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

export function CompaniesOutlineIcon(options: IconOptions): JSX.Element {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <path
        opacity='0.8'
        d='M2.375 17.5H18.625M3.625 2.5V17.5M12.375 2.5V17.5M17.375 6.25V17.5M6.125 5.625H6.75M6.125 8.125H6.75M6.125 10.625H6.75M9.25 5.625H9.875M9.25 8.125H9.875M9.25 10.625H9.875M6.125 17.5V14.6875C6.125 14.17 6.545 13.75 7.0625 13.75H8.9375C9.455 13.75 9.875 14.17 9.875 14.6875V17.5M3 2.5H13M12.375 6.25H18M14.875 9.375H14.8817V9.38167H14.875V9.375ZM14.875 11.875H14.8817V11.8817H14.875V11.875ZM14.875 14.375H14.8817V14.3817H14.875V14.375Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
