import useDialogContext from '@hooks/useDialogContext';
import { IRequest } from '@shared/interfaces/IRequest';
import { Button, ButtonProps } from 'primereact/button';

interface AnswerButtonProps extends ButtonProps {
  request: IRequest;
}

const AnswerButton = (props: AnswerButtonProps): JSX.Element => {
  const { request, ...restProps } = props;

  const { openDialog } = useDialogContext();

  const onClick = (): void => openDialog('answer-pcf-dialog', { request });

  return (
    <div>
      <Button {...restProps} onClick={onClick}>
        Answer
      </Button>
    </div>
  );
};

export default AnswerButton;
