import useDialogContext from '@hooks/useDialogContext';
import { Button } from 'primereact/button';

const UpdatePcfValues = (): JSX.Element => {
  const { openDialog } = useDialogContext();

  const onClick = (): void => {
    openDialog('update-pcf-values-dialog');
  };

  return (
    <div className='grid grid-nogutter w-full'>
      <div className='col md:col-4 xl:col-3'>
        <Button
          label='Update PCFs with new calculations'
          outlined
          onClick={onClick}
          className='w-full'
        />
      </div>
    </div>
  );
};

export default UpdatePcfValues;
