import { IProduct } from '@shared/interfaces/IProduct';
import { ColumnSortEvent } from 'primereact/column';

export const productRegionSortFunction = (event: ColumnSortEvent): IProduct[] => {
  const data = [...event.data] as IProduct[];

  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    const region1 = data1.region;
    const region2 = data2.region;

    let result = 1;

    // Using == instead of === to check for null or undefined
    if (region1 == null && region2 == null) result = 0;
    else if (region1 == null && region2 != null) result = -1;
    else if (typeof region1 === 'string' && typeof region2 === 'string')
      return region1.localeCompare(region2) * eventOrder;

    return eventOrder * result;
  });

  return data;
};
