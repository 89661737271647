import RequestsTable from './components/requests-table/RequestsTable';

const Requests = (): JSX.Element => (
  <div className='flex flex-column gap-1 justify-content-start align-items-start w-full'>
    <h1 className='text-2xl md:text-4xl'>Requests</h1>
    <RequestsTable />
  </div>
);

export default Requests;
