import ImportUsers from './components/import-users/ImportUsers';
import UsersTable from './components/users-table/UsersTable';

const Users = (): JSX.Element => (
  <div className='flex flex-column gap-1 justify-content-start align-items-start w-full'>
    <h1 className='text-2xl md:text-4xl'>Users</h1>

    <div className='flex flex-column gap-2 align-items-start mb-2 w-full'>
      <ImportUsers />
    </div>
    <UsersTable />
  </div>
);

export default Users;
