import { LicenseType, mapLicenseTypeToLabel } from '@shared/interfaces/ICompany';
import { Button } from 'primereact/button';

type ChangeLicenseButtonProps = {
  license: LicenseType;
  onClick: () => void;
};

function ChangeLicenseButton(props: ChangeLicenseButtonProps): JSX.Element | null {
  const { license, onClick } = props;

  if (!license) {
    return null;
  }

  return (
    <Button text className='underline' onClick={onClick}>
      {mapLicenseTypeToLabel(license)}
    </Button>
  );
}

export default ChangeLicenseButton;
