import { mapRequestSourceToLabel, RequestSource } from '@shared/enums/RequestType';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Dispatch, SetStateAction } from 'react';

import styles from './RequestSourceFilter.module.css';

type RequestSourceFilter = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

type DropdownOption = {
  label: string;
  value: string;
};

const RequestSourceFilter = ({ value, setValue }: RequestSourceFilter): JSX.Element => {
  const onChange = (e: DropdownChangeEvent): void => {
    setValue(e.value || '');
  };

  const dropdownOptions: DropdownOption[] = Object.values(RequestSource).map((reqSource) => ({
    label: mapRequestSourceToLabel(reqSource),
    value: reqSource,
  }));

  const itemTemplate = (option: DropdownOption): JSX.Element => <span>{option.label}</span>;

  const valueTemplate = (option: string): JSX.Element => {
    if (option) {
      return (
        <>
          <span className='text-primary'>Request Source:</span>{' '}
          <span>{mapRequestSourceToLabel(option as RequestSource)}</span>
        </>
      );
    }
    return <span>Request Source</span>;
  };

  return (
    <div className={styles['dropdown']}>
      <Dropdown
        className={classNames(
          {
            'min-w-12rem max-w-25rem': value,
            'md:w-13rem': !value,
          },
          'w-full',
        )}
        options={dropdownOptions}
        value={value || null}
        placeholder='Request Source'
        valueTemplate={valueTemplate(value)}
        itemTemplate={itemTemplate}
        onChange={onChange}
        showClear
        pt={{
          input: {
            className: 'text-900',
          },
        }}
      />
    </div>
  );
};

export default RequestSourceFilter;
