import ProductService from '@services/ProductService';
import { Button } from 'primereact/button';
import { useEffect } from 'react';

const ExportProducts = (): JSX.Element => {
  const productService = new ProductService();

  // Get isLoading and isFetching from listProducts RQ Query
  // So that we disable export button when while data is being fetched
  // Also, we rely on the RQ's caching mechanism to prevent multiple requests
  const { isLoading, isFetching } = productService.listProducts();

  const {
    mutate: exportProducts,
    isLoading: isExporting,
    isSuccess,
    data,
  } = productService.exportProducts({});

  const onClick = async (): Promise<void> => {
    exportProducts();
  };

  useEffect(() => {
    if (isSuccess && data) {
      // If mutation was successful and we have data, then download the file
      const { fileUrl } = data;
      const filename = fileUrl.split('/').pop() as string;

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = fileUrl;

      // // Set the download attribute if you want to specify the file name
      link.download = filename; // Use the last part of the URL as the file name

      // // Append the link to the document body (this step is necessary for some browsers)
      document.body.appendChild(link);

      // // Programmatically trigger a click event on the link
      link.click();

      // // Clean up by removing the link element
      document.body.removeChild(link);
    }
  }, [isSuccess, data]);

  return (
    <div className='grid grid-nogutter w-full'>
      <div className='col md:col-4 xl:col-3'>
        <Button
          disabled={isLoading || isFetching || isExporting}
          loading={isExporting}
          label='Export Products'
          icon='pi pi-upload'
          outlined
          onClick={onClick}
          className='w-full'
        />
      </div>
    </div>
  );
};

export default ExportProducts;
