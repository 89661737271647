import { Button } from 'primereact/button';

type CompaniesTableHeaderProps = {
  refetch: () => void;
  isFetching: boolean;
};

const CompaniesTableHeader = ({ refetch, isFetching }: CompaniesTableHeaderProps): JSX.Element => (
  <div className='flex flex-row justify-content-end w-full'>
    <Button icon='pi pi-refresh' rounded onClick={refetch} loading={isFetching} />
  </div>
);

export default CompaniesTableHeader;
