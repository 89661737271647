import { IRequest } from '@shared/interfaces/IRequest';
import { ColumnSortEvent } from 'primereact/column';

export const requestSortUserFullnameFunction = (event: ColumnSortEvent): unknown => {
  // Sort by first_name + last_name
  const data = [...event.data] as IRequest[];

  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    const userFullName1 = data1?.user.first_name + ' ' + data1?.user.last_name;
    const userFullName2 = data2?.user.first_name + ' ' + data2?.user.last_name;

    let result = 1;

    // Using == instead of === to check for null or undefined
    if (userFullName1 == null && userFullName2 == null) result = 0;
    else if (userFullName1 == null && userFullName2 != null) result = -1;
    else if (typeof userFullName1 === 'string' && typeof userFullName2 === 'string')
      return userFullName1.localeCompare(userFullName2) * eventOrder;

    return eventOrder * result;
  });

  return data;
};
