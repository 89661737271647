import { Auth } from 'aws-amplify';

type LoginProps = {
  email: string;
  password: string;
  staySignedIn: boolean;
};

class AmplifyAuthService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async getCurrentAuthenticatedUser(): Promise<any> {
    const _user = await Auth.currentAuthenticatedUser();

    return await _user;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async loginWithEmail({ email, password, staySignedIn }: LoginProps): Promise<any> {
    Auth.configure({
      storage: staySignedIn ? window.localStorage : window.sessionStorage,
    });

    // Save staySignedIn to localStorage, for use in App.tsx to configure Auth (for smooth F5 refreshing)
    localStorage.setItem('staySignedIn', staySignedIn.toString());

    const user = await Auth.signIn(email, password);
    if (!user) {
      throw new Error('No user found');
    }

    const session = user.signInUserSession;
    const idToken = session.getIdToken();
    const idTokenPayload = idToken.decodePayload();
    const userGroups = idTokenPayload['cognito:groups'];

    if (
      userGroups === undefined ||
      !userGroups ||
      userGroups.length === 0 ||
      !userGroups.includes('super-admin')
    ) {
      await this.signOut();
      throw new Error('User must be an admin');
    }

    return user;
  }

  public async signOut(): Promise<void> {
    const currentUser = await this.getCurrentAuthenticatedUser();

    if (currentUser) {
      await Auth.signOut();
    }
  }
}

export default AmplifyAuthService;
