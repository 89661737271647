import { DialogMapper, DialogType } from '@components/dialog-manager/DialogMapper';
import { IDialogContext } from '@shared/interfaces/IDialogContext';
import React, { useCallback, useMemo, useState } from 'react';

import DialogContext, { PropsType } from './DialogContext';

interface DialogProviderProps {
  children: React.ReactNode;
}

export const DialogProvider = ({ children }: DialogProviderProps): JSX.Element => {
  const [activeDialog, setActiveDialog] = useState<DialogType | null>(null);
  const [dialogProps, setDialogProps] = useState<PropsType<DialogType>>({});

  const closeDialog = useCallback((): void => {
    if (activeDialog !== null && dialogProps?.visible) {
      // When we want to close the dialog, we set props.visible to false
      // So that the side-effects of the dialog will be cleaned up by PrimeReact itself
      setDialogProps({ ...dialogProps, visible: false });
    }
  }, [activeDialog, dialogProps]);

  const openDialog = useCallback(
    <T extends DialogType>(dialog: T, props?: PropsType<T>): void => {
      if (dialog in DialogMapper) {
        // If such dialog is defined in DialogMapper:
        // 1. Set props.visible to true so that the dialog will be shown by PrimeReact
        // 2. Set activeDialog to the dialog and props
        setActiveDialog(dialog);
        setDialogProps({ ...props, visible: true });
      }
    },
    [activeDialog, dialogProps, closeDialog],
  );

  const state = useMemo<IDialogContext>(
    () => ({
      activeDialog,
      dialogProps,
      openDialog,
      closeDialog,
    }),
    [activeDialog, openDialog, closeDialog],
  );

  return <DialogContext.Provider value={state}>{children}</DialogContext.Provider>;
};
