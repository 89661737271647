import IAmplifyApiConfig from '@shared/interfaces/IAmplifyApiConfig';

import AmplifyApiService from './AmplifyApiService';

interface IGetSignedUrlProps {
  filename: string;
  type: string;
}

interface IGetSignedUrlResponse {
  url: string;
}

interface IUploadFileProps {
  file: File;
  signedUrl: string;
}

class ImportFileService {
  private amplifyApiService: AmplifyApiService;

  constructor() {
    this.amplifyApiService = new AmplifyApiService();
  }

  async getSignedUrl({ filename, type }: IGetSignedUrlProps): Promise<string> {
    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: '/signed-url',
      init: {},
    };

    const response: IGetSignedUrlResponse = await this.amplifyApiService.post<
      IGetSignedUrlProps,
      IGetSignedUrlResponse
    >({
      config: amplifyApiConfig,
      body: {
        filename,
        type,
      },
    });

    const { url } = response;

    if (!url) {
      throw new Error('No signed URL returned from API');
    }

    return url;
  }

  async uploadFile({ file, signedUrl }: IUploadFileProps): Promise<void> {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
    });

    if (!response.ok) {
      throw new Error('Error uploading file');
    }
  }
}

export default ImportFileService;
