import CompanyFilter from '@components/company-filter/CompanyFilter';
import { Button } from 'primereact/button';
import { Dispatch, SetStateAction } from 'react';

import RequestSourceFilter from './components/request-source-filter/RequestSourceFilter';
import UserFilter from './components/user-filter/UserFilter';

type RequestsTableHeaderProps = {
  refetch: () => void;
  isFetching: boolean;
  companyId: string;
  setCompanyId: Dispatch<SetStateAction<string>>;
  requestSource: string;
  setRequestSource: Dispatch<SetStateAction<string>>;
  requestedById: string;
  setRequestedById: Dispatch<SetStateAction<string>>;
};

const RequestsTableHeader = ({
  refetch,
  isFetching,
  companyId,
  setCompanyId,
  requestSource,
  setRequestSource,
  requestedById,
  setRequestedById,
}: RequestsTableHeaderProps): JSX.Element => (
  <div className='flex flex-wrap md:flex-nowrap md:flex-row gap-3 w-full justify-content-between md:justify-content-end align-items-center'>
    <span className='font-bold w-full flex flex-row align-items-center justify-content-end'>
      Filters:
    </span>

    <div className='flex flex-wrap flex-column md:flex-row md:flex-nowrap gap-3 justify-content-end align-items-end md:align-items-center md:flex-row min-w-full md:min-w-max'>
      <div className='flex flex-column gap-2 align-items-stretch md:flex md:flex-row md:align-items-end'>
        <UserFilter value={requestedById} setValue={setRequestedById} />
        <CompanyFilter value={companyId} setValue={setCompanyId} />
        <RequestSourceFilter value={requestSource} setValue={setRequestSource} />
      </div>
      <Button icon='pi pi-refresh' rounded onClick={refetch} loading={isFetching} />
    </div>
  </div>
);

export default RequestsTableHeader;
