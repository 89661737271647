import { RouterPath } from '@config/router';
import { Button, ButtonProps } from 'primereact/button';
import { classNames, IconOptions } from 'primereact/utils';
import { useMatch, useNavigate } from 'react-router-dom';

import styles from './HeaderLink.module.css';

type HeaderLinkProps = {
  path: RouterPath;
  label: string;
  icon?: (options: IconOptions<ButtonProps, unknown>) => JSX.Element;
  activeIcon?: (options: IconOptions<ButtonProps, unknown>) => JSX.Element;
};

const HeaderLink = ({ path, label, icon, activeIcon }: HeaderLinkProps): JSX.Element => {
  const match = useMatch(path);
  const navigate = useNavigate();

  const onClick = (): void => {
    navigate(path);
  };

  return (
    <Button
      className={classNames('border-noround h-full px-4 text-white', styles['header-link'], {
        [styles['active-border'] as string]: !!match,
      })}
      onClick={onClick}
      icon={match ? activeIcon : icon}
      label={label}
      text
    />
  );
};

export default HeaderLink;
