import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useMatch, useNavigate } from 'react-router-dom';

type MobileHeaderLinkProps = {
  path: string;
  label: string;
};

const MobileHeaderLink = ({ path, label }: MobileHeaderLinkProps): JSX.Element => {
  const match = useMatch(path);
  const navigate = useNavigate();

  const onClick = (): void => {
    navigate(path);
  };

  return (
    <Button
      pt={{
        label: {
          className: classNames('text-lg', 'text-gray-900', {
            'font-light': !match,
            'font-bold': match,
          }),
        },
      }}
      onClick={onClick}
      label={label}
      text
    />
  );
};

export default MobileHeaderLink;
