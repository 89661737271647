/**
 * Mapping between License Type and backend value returned
 */
export enum LicenseType {
  BASIC = 'basic',
  PRO = 'pro',
  AUTOMOTIVE = 'automotive',
}

export type LicenseHistoryItem = {
  id: number;
  company_id: number;
  previous_license: LicenseType | null;
  license: LicenseType;
  issued_by: number;
  date_created: string;
  email: string;
};

export const mapLicenseTypeToLabel = (license: LicenseType): string => {
  switch (license) {
    case LicenseType.BASIC:
      return 'Basic';
    case LicenseType.PRO:
      return 'Pro';
    case LicenseType.AUTOMOTIVE:
      return 'Automotive';
    default:
      return 'Unknown';
  }
};

export interface ICompany {
  id: string;
  bpn?: string;
  company_id: string;
  company_name: string;
  date_updated?: Date;
  date_created?: Date;
  edc_configuration_status?: EdcConfigurationStatus;
  edc_base_url?: string;
  license: LicenseType;
  sustainable_alternative_available?: boolean;
}

export enum EdcConfigurationStatus {
  Pending = 'pending',
  FirstStepCompleted = 'first_step_completed',
  Completed = 'completed',
}

export interface ICompanyEDCConfiguration {
  cognito_client_id: string;
  cognito_client_secret: string;
  edc_base_url: string;
  edc_client_id: string;
  edc_client_secret: string;
  pcf_exchange_client_secret_key: string;
}
