import useNotificationContext from '@hooks/useNotificationContext';
import UserService from '@services/UserService';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { Button } from 'primereact/button';

type ResendInviteButtonProps = {
  userId: string;
  lastInvitedAt?: string;
};

const ResendInviteButton = ({
  userId,
  lastInvitedAt = '',
}: ResendInviteButtonProps): JSX.Element => {
  const userService = new UserService();
  const { notify } = useNotificationContext();

  const { isLoading, mutate: resendInvite } = userService.resendInvite({
    onSuccess: () =>
      notify({
        severity: ToastSeverity.SUCCESS,
        summary: 'Success',
        detail: 'Invite resent successfully!',
        life: 3000,
      }),
    onError: () =>
      notify({
        severity: ToastSeverity.ERROR,
        summary: 'Error',
        detail: 'Failed to resend invite!',
      }),
  });

  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  const formattedLastInvitedAt = `This user was last invited ${rtf.format(
    -Math.round((new Date().getTime() - new Date(lastInvitedAt).getTime()) / (1000 * 60 * 60 * 24)),
    'day',
  )}. `;

  return (
    <Button
      icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-send'}
      className='p-button-text p-0'
      about='Resend Invite'
      onClick={(): void => resendInvite({ userId })}
      tooltip={`${lastInvitedAt !== '' ? formattedLastInvitedAt : ''}Click to send invite again.`}
      tooltipOptions={{ position: 'top' }}
    />
  );
};

export default ResendInviteButton;
