import { EMAIL_REGEX } from '@shared/constants';
import * as yup from 'yup';

const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Invalid email address')
      .matches(EMAIL_REGEX, 'Incorrect email format.')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'Password must contain at least one symbol (!@#$%^&*(),.?":{}|<>)',
      ),
  })
  .required();

export default LoginSchema;
