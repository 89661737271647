import useAuthContext from '@hooks/useAuthContext';
import AmplifyAuthService from '@services/AmplifyAuthService';
import { IAuthContext } from '@shared/interfaces/IAuthContext';
import { useEffect, useMemo, useState } from 'react';

import AuthContext from './AuthContext';

type AuthProps = {
  children?: React.ReactNode;
};

export interface IAuth {
  sub: string;
  email: string;
  givenName?: string;
  familyName?: string;
}

export const AuthIsSignedIn = ({ children }: AuthProps): JSX.Element | null => {
  const { isAuthenticated }: IAuthContext = useAuthContext();

  if (isAuthenticated) {
    return <>{children}</>;
  }
  return null;
};

export const AuthIsNotSignedIn = ({ children }: AuthProps): JSX.Element | null => {
  const { isAuthenticated, isLoading }: IAuthContext = useAuthContext();

  if (!isAuthenticated && !isLoading) {
    return <>{children}</>;
  }

  return null;
};

const AuthProvider = ({ children }: AuthProps): JSX.Element | null => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<IAuth>();

  const amplifyAuthService = new AmplifyAuthService();

  useEffect(() => {
    async function fetchUser(): Promise<void> {
      try {
        const _user = await amplifyAuthService.getCurrentAuthenticatedUser();
        setUser(_user.attributes);
        setIsAuthenticated(true);
        setIsLoading(false);
      } catch (err: unknown) {
        resetState();
      }
    }
    fetchUser();
  }, []);

  async function loginWithEmail(
    email: string,
    password: string,
    staySignedIn: boolean,
  ): Promise<void> {
    try {
      const _user = await amplifyAuthService.loginWithEmail({
        email,
        password,
        staySignedIn,
      });

      setUser(_user.attributes);
      setIsAuthenticated(true);
    } catch (err) {
      setIsAuthenticated(false);
      throw err;
    }
  }

  async function signOut(): Promise<void> {
    await amplifyAuthService.signOut();
    resetState();
  }

  function resetState(): void {
    setIsAuthenticated(false);
    setIsLoading(false);
    setUser(undefined);
  }

  const state = useMemo<IAuthContext>(
    () => ({
      isAuthenticated,
      isLoading,
      user,
      loginWithEmail,
      signOut,
    }),
    [isAuthenticated, isLoading, user],
  );

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
