export enum NotificationEventType {
  ImportFile = 'import_file',
}

export enum FileImportType {
  IMPORT_COMPANIES = 'import_companies',
  IMPORT_USERS = 'import_users',
  IMPORT_PRODUCTS = 'import_products',
}

export type DetailErrorMessages = { row: number; message: string };

export interface INotification {
  eventType: NotificationEventType;
  type?: FileImportType;
  user_id?: string;
  success?: boolean;
  message?: string;
  errorRows?: number;
  detailErrorMessages?: DetailErrorMessages[];
}
