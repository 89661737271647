import { RouterPath } from '@config/router';
import useAuthContext from '@hooks/useAuthContext';
import { Avatar, AvatarProps } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import { MouseEvent, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserButton = (): JSX.Element => {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const { user, signOut } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const userInital = useMemo(() => {
    if (user?.givenName && user?.familyName) {
      return `${user.givenName?.charAt(0) ?? ''}${user.familyName?.charAt(0) ?? ''}`;
    }
    return null;
  }, [user]);

  const navigate = useNavigate();

  const onSignOut = async (): Promise<void> => {
    setIsLoading(true);

    await signOut?.();
    navigate(RouterPath.LOGIN);

    setIsLoading(false);
  };

  const onButtonClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    overlayPanelRef.current?.toggle(event);
  };

  const renderAvatar = (props: AvatarProps): JSX.Element =>
    userInital ? (
      <Avatar label={userInital} shape='circle' {...props} />
    ) : (
      <Avatar icon='pi pi-user' shape='circle' {...props} />
    );

  return (
    <>
      <a className={classNames('rounded-circle topbar-action')} onClick={onButtonClick}>
        <span className='icon-overlay'>
          {renderAvatar({
            className: 'bg-white rounded-circle',
            pt: { label: { className: 'text-sm' } },
          })}
        </span>
      </a>

      <OverlayPanel
        ref={overlayPanelRef}
        className='shadow-2 mt-2'
        pt={{
          content: { className: 'p-0' },
        }}
      >
        <div className='flex flex-column'>
          <div className='flex px-2 pt-2 align-items-center gap-2'>
            <div className='flex justify-content-center align-items-center'>
              {renderAvatar({
                className: 'rounded-circle',
                size: 'large',
                pt: { label: { className: 'text-lg font-semibold' } },
              })}
            </div>

            <div className='flex flex-column gap-1'>
              {(user?.givenName || user?.familyName) && (
                <span className='font-semibold text-black-alpha-90'>
                  {user?.givenName ?? ''} {user?.familyName ?? ''}
                </span>
              )}
              <span className='font-normal'>{user?.email}</span>
            </div>
          </div>

          <Divider
            type='solid'
            pt={{ root: { className: 'my-2 p-0' }, content: { className: 'p-0 border-none' } }}
          />

          <Button
            className={'mb-2 pl-5 font-light text-gray-800'}
            text
            onClick={onSignOut}
            disabled={isLoading}
          >
            {!isLoading ? (
              <i className='pi pi-sign-out mr-4 text-gray-800' />
            ) : (
              <i className='pi pi-spin p-spinner mr-4 text-gray-800' />
            )}
            Sign Out
          </Button>
        </div>
      </OverlayPanel>
    </>
  );
};

export default UserButton;
