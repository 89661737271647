import LoadingButton from '@components/loading-button/LoadingButton';
import { SupportedFileTypes } from '@shared/constants';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { RefObject } from 'react';

type ImportFileButtonProps = {
  name: string;
  uploadHandler: (event: FileUploadHandlerEvent) => void;
  disabled?: boolean;
  label: string;
  fileUploadRef: RefObject<FileUpload>;
};

const ImportFileButton = ({
  name,
  uploadHandler,
  disabled,
  label,
  fileUploadRef,
}: ImportFileButtonProps): JSX.Element => {
  const supportedFileFormats = SupportedFileTypes.map((fileType) => `.${fileType}`).join(', ');

  if (disabled) {
    return <LoadingButton label='Importing...' />;
  }

  return (
    <FileUpload
      name={name}
      chooseLabel={label}
      ref={fileUploadRef}
      mode='basic'
      auto
      customUpload
      uploadHandler={uploadHandler}
      accept={supportedFileFormats}
      disabled={disabled}
      pt={{
        basicButton: {
          className: 'w-full',
        },
      }}
    />
  );
};

export default ImportFileButton;
