export function removeUndefinedFields<T extends object>(obj: T): T {
  const _obj = { ...obj };

  Object.keys(_obj).forEach((key) => {
    if (
      _obj[key as keyof T] === undefined ||
      _obj[key as keyof T] === null ||
      _obj[key as keyof T] === ''
    ) {
      delete _obj[key as keyof T];
    }
  });

  return _obj;
}
