export enum RequestRegionType {
  Global = 'global',
  Region = 'region',
}

export enum RequestRegion {
  AsiaPacific = 'A',
  SouthAmerica = 'SA',
  NorthAmerica = 'N',
  Europe = 'E',
}

export const RequestRegionMap = {
  [RequestRegion.AsiaPacific]: 'Asia Pacific',
  [RequestRegion.SouthAmerica]: 'South America',
  [RequestRegion.NorthAmerica]: 'North America',
  [RequestRegion.Europe]: 'Europe',
};
