import { RegisterType } from '@hooks/useForm';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';

// This input field props will extend the InputText props from PrimeReact
interface InputTextProps extends React.ComponentPropsWithRef<typeof InputText> {
  name: string;
  error?: string;
  register: RegisterType;
  isLoading?: boolean;
  iconComponent?: JSX.Element;
  iconPosition?: 'left' | 'right';
}

const CustomInputText = ({
  name,
  register,
  error,
  isLoading = false,
  iconComponent,
  iconPosition,
  ...rest
}: InputTextProps): JSX.Element => {
  if (isLoading) {
    return (
      <div
        className='flex align-items-center justify-content-between bg-gray-100 p-3 border-1 border-gray-200 border-round'
        style={{ height: rest.style?.height }}
      >
        <Skeleton width='70%' />
      </div>
    );
  }

  return (
    <div
      className={classNames('flex flex-column gap-1', {
        'p-input-icon-right': iconPosition === 'right',
        'p-input-icon-left': iconPosition === 'left',
      })}
    >
      {iconComponent}
      <InputText {...rest} {...register(name)} />
      {error && <span className='text-red-500 text-sm'>{error}</span>}
    </div>
  );
};
export default CustomInputText;
