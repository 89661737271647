import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

export function UsersFilledIcon(options: IconOptions): JSX.Element {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <path
        d='M4.25 5.3125C4.25 4.40082 4.61216 3.52648 5.25682 2.88182C5.90148 2.23716 6.77582 1.875 7.6875 1.875C8.59918 1.875 9.47352 2.23716 10.1182 2.88182C10.7628 3.52648 11.125 4.40082 11.125 5.3125C11.125 6.22418 10.7628 7.09852 10.1182 7.74318C9.47352 8.38784 8.59918 8.75 7.6875 8.75C6.77582 8.75 5.90148 8.38784 5.25682 7.74318C4.61216 7.09852 4.25 6.22418 4.25 5.3125ZM12.375 7.1875C12.375 6.81816 12.4477 6.45243 12.5891 6.1112C12.7304 5.76997 12.9376 5.45993 13.1988 5.19876C13.4599 4.9376 13.77 4.73043 14.1112 4.58909C14.4524 4.44775 14.8182 4.375 15.1875 4.375C15.5568 4.375 15.9226 4.44775 16.2638 4.58909C16.605 4.73043 16.9151 4.9376 17.1762 5.19876C17.4374 5.45993 17.6446 5.76997 17.7859 6.1112C17.9273 6.45243 18 6.81816 18 7.1875C18 7.93342 17.7037 8.64879 17.1762 9.17624C16.6488 9.70368 15.9334 10 15.1875 10C14.4416 10 13.7262 9.70368 13.1988 9.17624C12.6713 8.64879 12.375 7.93342 12.375 7.1875ZM1.75 15.9375C1.75 14.3628 2.37556 12.8526 3.48905 11.7391C4.60255 10.6256 6.11278 10 7.6875 10C9.26222 10 10.7724 10.6256 11.8859 11.7391C12.9994 12.8526 13.625 14.3628 13.625 15.9375V15.94L13.6242 16.0392C13.6224 16.1451 13.5937 16.2489 13.5408 16.3408C13.4879 16.4326 13.4125 16.5095 13.3217 16.5642C11.621 17.5883 9.67272 18.128 7.6875 18.125C5.6275 18.125 3.69917 17.555 2.05417 16.5642C1.96321 16.5096 1.88764 16.4328 1.83457 16.3409C1.78151 16.2491 1.75269 16.1452 1.75083 16.0392L1.75 15.9375ZM14.875 15.94L14.8742 16.06C14.8695 16.3378 14.8032 16.611 14.68 16.86C16.1348 16.9497 17.5878 16.6597 18.8967 16.0183C18.9979 15.9689 19.0838 15.8929 19.1455 15.7986C19.2071 15.7043 19.242 15.5951 19.2467 15.4825C19.2761 14.7835 19.1245 14.0888 18.8066 13.4656C18.4887 12.8424 18.0153 12.3119 17.4321 11.9254C16.849 11.5389 16.1759 11.3096 15.4781 11.2596C14.7803 11.2096 14.0815 11.3406 13.4492 11.64C14.3768 12.8805 14.8769 14.3885 14.8742 15.9375L14.875 15.94Z'
        fill='white'
      />
    </svg>
  );
}
