class SessionStorageService {
  // Companies
  public getIsImportingCompanies = (): boolean =>
    sessionStorage.getItem('isImportingCompanies') === 'true';

  public setIsImportingCompanies = (): void => {
    sessionStorage.setItem('isImportingCompanies', 'true');
  };

  public deleteIsImportingCompanies = (): void => {
    window.dispatchEvent(new Event('finishedImportingCompanies'));
    sessionStorage.removeItem('isImportingCompanies');
  };

  // Products
  public getIsImportingProducts = (): boolean =>
    sessionStorage.getItem('isImportingProducts') === 'true';

  public setIsImportingProducts = (): void => {
    sessionStorage.setItem('isImportingProducts', 'true');
  };

  public deleteIsImportingProducts = (): void => {
    window.dispatchEvent(new Event('finishedImportingProducts'));
    sessionStorage.removeItem('isImportingProducts');
  };

  // Users
  public getIsImportingUsers = (): boolean => sessionStorage.getItem('isImportingUsers') === 'true';

  public setIsImportingUsers = (): void => {
    sessionStorage.setItem('isImportingUsers', 'true');
  };

  public deleteIsImportingUsers = (): void => {
    window.dispatchEvent(new Event('finishedImportingUsers'));
    sessionStorage.removeItem('isImportingUsers');
  };
}

export default SessionStorageService;
