import { IProduct } from '@shared/interfaces/IProduct';

// Remove duplicated products from the list
// And collect all companies that have this product into an array
export const removeDuplicatedProducts = (products: IProduct[]): IProduct[] => {
  const productsMap = new Map<string, IProduct>();

  // Remove duplicated products
  products.forEach((product) => {
    productsMap.set(product.product_id, product);
  });

  return Array.from(productsMap.values());
};
