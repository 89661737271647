import { RouterPath } from '@config/router';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';

type AppRouteProps = {
  component?: React.ComponentType;
  redirect?: RouterPath;
};

// A wrapper for <Route> that can redirect & set page title
const AppRoute = ({ component: Component, redirect }: AppRouteProps): JSX.Element => {
  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  if (!Component) {
    return <Navigate to={RouterPath.ANY} replace />;
  }

  return (
    <>
      <Helmet>
        <title>PACIFIC Admin Panel</title>
      </Helmet>
      <Component />
    </>
  );
};

export default AppRoute;
