import DialogManager from '@components/dialog-manager/DialogManager';
import AmplifyConfig from '@config/AmplifyConfig';
import { GuestRouter, MainRouter } from '@config/router';
import { DialogProvider } from '@contexts/dialog/DialogProvider';
import { Amplify, Auth } from 'aws-amplify';
import { PrimeReactProvider } from 'primereact/api';

import AuthProvider, { AuthIsNotSignedIn, AuthIsSignedIn } from './contexts/auth/AuthProvider';
import NotificationProvider from './contexts/notification/NotificationProvider';
import WebsocketProvider from './contexts/websocket/WebsocketProvider';

Amplify.configure(AmplifyConfig);
Auth.configure({
  storage: window.localStorage.getItem('staySignedIn') === 'true' ? localStorage : sessionStorage,
});

function App(): JSX.Element {
  return (
    <PrimeReactProvider value={{ ripple: true }}>
      <NotificationProvider>
        <DialogProvider>
          <DialogManager />

          <AuthProvider>
            {/* Router for authenticated user */}
            <AuthIsSignedIn>
              <WebsocketProvider>
                <MainRouter />
              </WebsocketProvider>
            </AuthIsSignedIn>

            {/* Router for unauthenticated user */}
            <AuthIsNotSignedIn>
              <GuestRouter />
            </AuthIsNotSignedIn>
          </AuthProvider>
        </DialogProvider>
      </NotificationProvider>
    </PrimeReactProvider>
  );
}

export default App;
