import { lazy } from 'react';

export const DialogMapper = {
  'comment-dialog': lazy(() => import('./dialogs/comment-dialog/CommentDialog')),
  'change-license-dialog': lazy(
    () => import('./dialogs/change-license-dialog/ChangeLicenseDialog'),
  ),
  'configure-edc-connection-dialog': lazy(
    () => import('./dialogs/configure-edc-connection-dialog/ConfigureEDCConnectionDialog'),
  ),
  'remove-product-company-dialog': lazy(
    () => import('./dialogs/remove-product-company-dialog/RemoveProductCompanyDialog'),
  ),
  'answer-pcf-dialog': lazy(() => import('./dialogs/answer-pcf-dialog/AnswerPcfDialog')),
  'get-started-dialog': lazy(() => import('./dialogs/get-started-dialog/GetStartedDialog')),
  'update-pcf-values-dialog': lazy(
    () => import('./dialogs/update-pcf-values-dialog/UpdatePcfValuesDialog'),
  ),
};

export type DialogType = keyof typeof DialogMapper;
