export enum RequestStatus {
  open = 'open',
  pending = 'pending',
  rejected = 'rejected',
  complete = 'complete',
  outdated = 'outdated',
  updated = 'updated',
}

export const mapRequestStatusToLabel = (status: RequestStatus): string => {
  switch (status) {
    case RequestStatus.open:
      return 'Open';
    case RequestStatus.pending:
      return 'Pending';
    case RequestStatus.complete:
      return 'Complete';
    case RequestStatus.rejected:
      return 'Declined';
    case RequestStatus.outdated:
      return 'Expired';
    case RequestStatus.updated:
      return 'Updated';
    default:
      return 'Unknown';
  }
};
