type FailedToGetProps = {
  label: string;
  reason: string;
};
const FailedToGet = ({ label, reason }: FailedToGetProps): JSX.Element => (
  <div className='flex flex-column gap-1 border-round-md p-2 bg-gray-100'>
    <span>
      {'\u26A0\uFE0F'} Failed to get {label}
    </span>
    <span className='text-sm text-gray-500'> Reason: {reason}</span>
    <span className='text-gray-600'>Please try again later</span>
  </div>
);

export default FailedToGet;
