import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

export function UsersOutlineIcon(options: IconOptions): JSX.Element {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <path
        opacity='0.8'
        d='M13 15.94C13.7108 16.1464 14.4473 16.2508 15.1875 16.25C16.3778 16.2517 17.5527 15.9803 18.6216 15.4567C18.6533 14.7098 18.4405 13.9729 18.0156 13.3578C17.5908 12.7427 16.977 12.2828 16.2672 12.0479C15.5575 11.813 14.7906 11.8159 14.0826 12.0562C13.3747 12.2964 12.7644 12.7609 12.3441 13.3792M13 15.94V15.9375C13 15.01 12.7616 14.1375 12.3441 13.3792M13 15.94V16.0283C11.3962 16.9942 9.55882 17.5032 7.68665 17.5C5.74415 17.5 3.92665 16.9625 2.37498 16.0283L2.37415 15.9375C2.37351 14.7579 2.76549 13.6116 3.4883 12.6794C4.21111 11.7472 5.22362 11.082 6.36622 10.7888C7.50882 10.4956 8.7165 10.5911 9.79887 11.0601C10.8812 11.5291 11.7767 12.345 12.3441 13.3792M10.5 5.3125C10.5 6.05842 10.2037 6.77379 9.67622 7.30124C9.14877 7.82868 8.4334 8.125 7.68748 8.125C6.94156 8.125 6.22619 7.82868 5.69874 7.30124C5.1713 6.77379 4.87498 6.05842 4.87498 5.3125C4.87498 4.56658 5.1713 3.85121 5.69874 3.32376C6.22619 2.79632 6.94156 2.5 7.68748 2.5C8.4334 2.5 9.14877 2.79632 9.67622 3.32376C10.2037 3.85121 10.5 4.56658 10.5 5.3125ZM17.375 7.1875C17.375 7.76766 17.1445 8.32406 16.7343 8.7343C16.324 9.14453 15.7676 9.375 15.1875 9.375C14.6073 9.375 14.0509 9.14453 13.6407 8.7343C13.2304 8.32406 13 7.76766 13 7.1875C13 6.60734 13.2304 6.05094 13.6407 5.6407C14.0509 5.23047 14.6073 5 15.1875 5C15.7676 5 16.324 5.23047 16.7343 5.6407C17.1445 6.05094 17.375 6.60734 17.375 7.1875Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
