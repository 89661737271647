/* eslint-disable camelcase */

import FailedToGet from '@components/failed-to-get/FailedToGet';
import ProductId from '@components/product-id/ProductId';
import ProductName from '@components/product-name/ProductName';
import RequestService from '@services/RequestService';
import { mapRequestStatusToLabel, RequestStatus } from '@shared/enums/RequestStatus';
import { mapRequestSourceToLabel, RequestSource, RequestType } from '@shared/enums/RequestType';
import { getRequestStatusColors } from '@shared/helpers/get-request-status-colors';
import { parseDateWithoutTime } from '@shared/helpers/parse-date';
import { requestProductIdSortFunction } from '@shared/helpers/request-product-id-sort-function';
import { requestProductNameSortFunction } from '@shared/helpers/request-product-name-sort-function';
import { requestRegionSortFunction } from '@shared/helpers/request-region-sort-function';
import { requestRequestSourceSortFunction } from '@shared/helpers/request-request-source-sort-function';
import { requestSortUserFullnameFunction } from '@shared/helpers/request-user-fullname-sort-function';
import { IRequest } from '@shared/interfaces/IRequest';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';

import AnswerButton from '../answer-button/AnswerButton';
import CommentIconButton from '../comment-icon-button/CommentIconButton';
import RequestsTableHeader from './components/requests-table-header/RequestsTableHeader';
import styles from './RequestsTable.module.css';

const RequestsTable = (): JSX.Element | null => {
  const [companyId, setCompanyId] = useState<string>('');
  const [requestedById, setRequestedById] = useState<string>('');
  const [requestSource, setRequestSource] = useState<string>('');
  const requestService = new RequestService();

  // RQ Query to get requests
  const {
    isLoading: isGetRequestsLoading,
    isFetching: isGetRequestsFetching,
    isError: isGetRequestsError,
    error: getRequestsError,
    data: requests,
    refetch: refetchRequests,
  } = requestService.listRequests({
    company_id: companyId,
    request_source: requestSource,
    requested_by: requestedById,
  });

  const productNameTemplate = (rowData: IRequest): React.ReactNode => {
    let productName;
    let requestedProductName;

    if (rowData.request_type === RequestType.additional_component) {
      if (rowData.status !== RequestStatus.complete) {
        // If it is additional product request and hasn't been answered yet, then show additional product id as main product id
        productName = rowData?.other_request_data?.component_name;
      } else {
        // If it is additional product request and is already answered, show product id as main product id and additional product id below it
        productName = rowData?.product?.product_name;
        requestedProductName = rowData?.other_request_data?.component_name;
      }
    } else {
      // If it is not additional product request, show product id as main product id
      productName = rowData?.product?.product_name;
    }

    return <ProductName productName={productName} requestedProductName={requestedProductName} />;
  };

  const productIdTemplate = (rowData: IRequest): React.ReactNode => {
    let productId;
    let requestedProductId;

    if (rowData.request_type === RequestType.additional_component) {
      if (rowData.status !== RequestStatus.complete) {
        // If it is additional product request and hasn't been answered yet, then show additional product id as main product id
        productId = rowData?.other_request_data?.component_id;
      } else {
        // If it is additional product request and is already answered, show product id as main product id and additional product id below it
        productId = rowData?.product?.product_id;
        requestedProductId = rowData?.other_request_data?.component_id;
      }
    } else {
      // If it is not additional product request, show product id as main product id
      productId = rowData?.product?.product_id;
    }

    return <ProductId productId={productId} requestedProductId={requestedProductId} />;
  };

  const requestedByTemplate = (rowData: IRequest): React.ReactNode =>
    `${rowData.user.first_name} ${rowData.user.last_name}`;

  const statusTemplate = (rowData: IRequest): React.ReactNode => {
    const { backgroundColor, color } = getRequestStatusColors(rowData.status);

    return (
      <div className='flex w-full justify-content-center align-items-center'>
        <div
          style={{
            padding: '2px 12px 2px 12px',
            borderRadius: '100px',
            backgroundColor,
            color,
          }}
          className='w-full justify-content-center flex'
        >
          {mapRequestStatusToLabel(rowData.status)}
        </div>
      </div>
    );
  };

  const dateRequestedTemplate = (rowData: IRequest): React.ReactNode =>
    parseDateWithoutTime(rowData.date_created ?? '').toLocaleString();

  const requestSourceTemplate = (rowData: IRequest): React.ReactNode => {
    if (!rowData?.request_source) return null;

    return mapRequestSourceToLabel(rowData.request_source);
  };

  const commentTemplate = (rowData: IRequest): JSX.Element | null => {
    if (!rowData?.request_description || rowData?.request_source === RequestSource.internal_auto) {
      return null;
    }

    return <CommentIconButton request={rowData} />;
  };

  const answerTemplate = (rowData: IRequest): JSX.Element | null => {
    if (
      rowData.status !== RequestStatus.pending ||
      (rowData.request_type !== RequestType.external_pcf &&
        rowData.request_type !== RequestType.pcf &&
        rowData.request_type !== RequestType.additional_component)
    ) {
      return null;
    }

    return <AnswerButton request={rowData} />;
  };

  if (isGetRequestsError) {
    return <FailedToGet label='companies' reason={getRequestsError.message} />;
  }

  return (
    <div className='w-full'>
      <DataTable
        value={requests}
        sortField='date_created'
        sortOrder={-1}
        className={styles['requests-table']}
        header={
          <RequestsTableHeader
            refetch={refetchRequests}
            isFetching={isGetRequestsFetching}
            companyId={companyId}
            setCompanyId={setCompanyId}
            requestSource={requestSource}
            setRequestSource={setRequestSource}
            requestedById={requestedById}
            setRequestedById={setRequestedById}
          />
        }
        loading={isGetRequestsLoading}
      >
        <Column
          sortable
          sortField='request_type'
          sortFunction={requestRequestSourceSortFunction}
          header='Request Source'
          className='w-1'
          body={requestSourceTemplate}
        />

        <Column body={commentTemplate} />

        <Column
          body={productNameTemplate}
          sortable
          sortField='product.product_name'
          sortFunction={requestProductNameSortFunction}
          className='w-4'
          header='Product Name'
        />

        <Column
          body={productIdTemplate}
          sortable
          sortField='product.product_id'
          sortFunction={requestProductIdSortFunction}
          header='Product ID'
          className='w-1'
        />

        <Column
          field='region'
          sortable
          sortField='region'
          sortFunction={requestRegionSortFunction}
          header='Region'
          className='w-1'
        />

        <Column
          body={dateRequestedTemplate}
          sortable
          sortField='date_created'
          header='Date Requested'
          className='w-1'
        />

        <Column field='company.company_name' sortable header='Company Name' className='w-2' />

        <Column
          body={requestedByTemplate}
          sortable
          sortField='first_name'
          sortFunction={requestSortUserFullnameFunction}
          header='Requested By'
          className='w-2'
        />

        <Column
          body={statusTemplate}
          sortable
          sortField='status'
          header='Status'
          className='w-1rem'
        />

        <Column body={answerTemplate} className='w-1' />
      </DataTable>
    </div>
  );
};

export default RequestsTable;
