import { DetailErrorMessages } from '@shared/interfaces/INotification';

type NotificationErrorContentProps = {
  title: string;
  detailErrorMessages?: DetailErrorMessages[];
};

const NotificationErrorContent = ({
  title,
  detailErrorMessages,
}: NotificationErrorContentProps): JSX.Element | null => {
  if (detailErrorMessages?.length === 0) {
    return null;
  }

  return (
    <div className='flex flex-column'>
      <div className='flex flex-row align-items-center gap-2'>
        <i className='pi pi-exclamation-triangle font text-4xl' />
        <h6>{title}</h6>
      </div>
      <div className='overflow-auto w-full' style={{ maxHeight: 'calc(100vh - 200px)' }}>
        <ul>
          {detailErrorMessages?.map((detailErrorMessage) => (
            <li key={detailErrorMessage.row}>
              Row {detailErrorMessage.row + 2}: <b>{detailErrorMessage.message}</b>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NotificationErrorContent;
