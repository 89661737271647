import { INotificationContext } from '@shared/interfaces/INotificationContext';
import { IToast } from '@shared/interfaces/IToast';
import { Toast } from 'primereact/toast';
import { useMemo, useRef } from 'react';

import NotificationContext from './NotificationContext';

type NotificationProviderProps = {
  children: React.ReactNode;
};

const NotificationProvider = ({ children }: NotificationProviderProps): JSX.Element => {
  const toastRef = useRef<Toast>(null);

  const notify = ({ severity, summary, detail, life, content }: IToast): void => {
    if (toastRef.current) {
      toastRef.current.show({
        severity,
        summary,
        detail,
        life,
        content: content || null,
        sticky: !life,
      });
    }
  };

  const state = useMemo<INotificationContext>(() => ({ notify }), [notify]);

  return (
    <NotificationContext.Provider value={state}>
      <Toast ref={toastRef} />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
