import { IAuthContext } from '@shared/interfaces/IAuthContext';
import { createContext } from 'react';

const defaultState: IAuthContext = {
  isAuthenticated: false,
  isLoading: true,
  user: undefined,
};

const AuthContext = createContext(defaultState);

export default AuthContext;
