import ImportFileButton from '@components/import-file-button/ImportFileButton';
import useNotificationContext from '@hooks/useNotificationContext';
import ProductService from '@services/ProductService';
import SessionStorageService from '@services/SessionStorageService';
import { SupportedFileTypes } from '@shared/constants';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { useQueryClient } from '@tanstack/react-query';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { useEffect, useRef, useState } from 'react';

const ImportProducts = (): JSX.Element => {
  const queryClient = useQueryClient();

  const { notify } = useNotificationContext();

  const productService = new ProductService();
  const sessionStorageService = new SessionStorageService();

  const fileUploadRef = useRef<FileUpload>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(
    sessionStorageService.getIsImportingProducts(),
  );

  const onMutate = (): void => {
    notify({
      severity: ToastSeverity.INFO,
      summary: 'Import started...',
      detail: 'You will receive a notification once the import is complete.',
      life: 5000,
    });

    sessionStorageService.setIsImportingProducts();
  };

  // RQ Mutation for importing products
  const { isLoading, mutate: importProducts } = productService.importProducts({
    onMutate,
  });

  const handleFinishedImportingProductsEvent = (): void => {
    queryClient.invalidateQueries({ queryKey: ['listProducts'] });
    queryClient.invalidateQueries({ queryKey: ['listCompaniesByProductId'] });

    fileUploadRef.current?.clear();
    setIsProcessing(false);
  };

  const uploadHandler = (e: FileUploadHandlerEvent): void => {
    const file = e.files[0];
    const fileFormat = file.name.split('.').pop() as string;

    if (!SupportedFileTypes.includes(fileFormat)) {
      notify({
        severity: ToastSeverity.ERROR,
        summary: 'Invalid file format',
        detail: `Supported formats: ${SupportedFileTypes.map((fileType) => '.' + fileType).join(
          ', ',
        )}`,
        life: 5000,
      });

      fileUploadRef.current?.clear();

      return;
    }

    importProducts({ file });
    setIsProcessing(true);
  };

  useEffect(() => {
    // When a message is received from the websocket for importing products,
    //  the 'finishedImportingProducts' event is dispatched
    // That means the import and processing are completed
    window.addEventListener('finishedImportingProducts', handleFinishedImportingProductsEvent);

    return () =>
      window.removeEventListener('finishedImportingProducts', handleFinishedImportingProductsEvent);
  }, []);

  const textComponent = (
    <div className='w-full md:pl-2 pb-2 md:pb-0 line-height-1'>
      <p className='m-0'>Please import an Excel file with a list of products.</p>

      <div className='m-0 flex flex-row'>
        <span className='white-space-nowrap'>Take a look at our</span>
        <a
          href={
            process.env.REACT_APP_S3_CONTENT_URL + '/sample-uploads/Pacific+Sample+Products.xlsx'
          }
          download='Pacific Sample Products.xlsx'
          target='_blank'
          rel='noreferrer'
          className='text-primary mx-1 underline'
        >
          sample
        </a>
        <span className='white-space-nowrap'>Excel file.</span>
      </div>
    </div>
  );

  return (
    <div className='grid grid-nogutter w-full '>
      <div className='col md:align-items-center flex flex-column-reverse flex-column md:flex-row'>
        <div className='col md:col-4 xl:col-3 p-0'>
          <ImportFileButton
            label='Import Products'
            name='importProducts'
            disabled={isProcessing || isLoading}
            uploadHandler={uploadHandler}
            fileUploadRef={fileUploadRef}
          />
        </div>
        {textComponent}
      </div>
    </div>
  );
};

export default ImportProducts;
