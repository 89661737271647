import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

export function ProductsFilledIcon(options: IconOptions): JSX.Element {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <g clipPath='url(#clip0_7945_5930)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.77847 3.80531C4.76369 4.79703 3.12913 5.6223 3.14605 5.63922C3.1947 5.68787 10.4368 9.50999 10.4836 9.51171C10.5248 9.51327 17.7999 5.53658 17.8542 5.48289C17.8857 5.45175 10.5903 1.9966 10.5003 2C10.468 2.00121 8.79321 2.81363 6.77847 3.80531ZM15.1984 8.28426C13.1296 9.41922 11.4273 10.3575 11.4154 10.3694C11.4036 10.3813 11.8163 10.9195 12.3327 11.5653L13.2716 12.7396L16.4477 10.9218C18.1946 9.92194 19.821 8.98992 20.0619 8.85055L20.5 8.59724L20.3355 8.33439C19.6463 7.23353 18.9955 6.21876 18.9792 6.21962C18.9684 6.2202 17.2671 7.14929 15.1984 8.28426ZM1.24294 7.48333L0.5 8.59677L0.625699 8.67366C0.694859 8.71594 2.26358 9.61553 4.11175 10.6727C5.95992 11.7299 7.52649 12.628 7.59304 12.6686L7.71405 12.7423L8.6243 11.6024C9.12491 10.9754 9.53643 10.4389 9.53874 10.4102C9.54225 10.3668 6.2645 8.61001 2.61903 6.70135L1.98592 6.36989L1.24294 7.48333ZM2.37298 12.993V14.9228L6.12665 16.9042C8.19117 17.994 9.88801 18.878 9.89743 18.8685C9.90685 18.8591 9.91009 17.2842 9.90458 15.3687L9.89462 11.886L9.09361 12.8901C8.65306 13.4423 8.23286 13.9341 8.15984 13.983C7.99538 14.0931 7.77492 14.0984 7.57623 13.997C7.49555 13.9558 6.30682 13.2789 4.9346 12.4927C3.56238 11.7065 2.42464 11.0632 2.40631 11.0632C2.38799 11.0632 2.37298 11.9316 2.37298 12.993ZM15.9126 12.5707C13.0981 14.185 13.165 14.1534 12.8663 14.0116C12.7803 13.9708 12.4763 13.6221 11.9227 12.9293L11.1059 11.9072L11.0957 13.6538C11.0901 14.6144 11.0901 16.1812 11.0957 17.1354L11.1059 18.8706L14.8472 16.8163L18.5885 14.762V12.9126C18.5885 11.8954 18.5753 11.0646 18.5591 11.0664C18.543 11.0681 17.3521 11.745 15.9126 12.5707Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_7945_5930'>
          <rect width='20' height='20' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
