import IAmplifyApiConfig from '@shared/interfaces/IAmplifyApiConfig';
import {
  ICompany,
  ICompanyEDCConfiguration,
  LicenseHistoryItem,
} from '@shared/interfaces/ICompany';
import { FileImportType } from '@shared/interfaces/INotification';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from './AmplifyApiService';
import {
  CreateCompanyAppClientInput,
  CreateCompanyAppClientResponse,
  SaveCompanyEDCConfigurationInput,
  SaveCompanyEDCConfigurationMutationProps,
  TestCompanyEDCConfigurationMutationProps,
  UpdateLicenseInput,
  UpdateLicenseMutationProps,
} from './CompanyService.types';
import ImportFileService from './ImportFileService';

type RemoveProductCompanyProps = {
  productId: string;
  companyId: string;
};

type UploadCompaniesProps = {
  file: File;
};

type RQMutationSideEffectProps = {
  onMutate?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
};

class CompanyService {
  private amplifyApiService: AmplifyApiService;
  private importFileService: ImportFileService;

  constructor() {
    this.amplifyApiService = new AmplifyApiService();
    this.importFileService = new ImportFileService();
  }

  listCompanies(): UseQueryResult<ICompany[], Error> {
    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: '/company',
      init: {},
    };

    const query = useQuery<ICompany[], Error>({
      queryKey: ['listCompanies'],
      queryFn: async (): Promise<ICompany[]> => {
        const response = await this.amplifyApiService.get<{ companies: ICompany[] }>({
          config: amplifyApiConfig,
        });

        return response.companies;
      },
    });

    return query;
  }

  listCompaniesByProductId(id: string): UseQueryResult<ICompany[], Error> {
    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: '/company',
      init: {
        queryStringParameters: {
          // eslint-disable-next-line camelcase
          component_id: id,
        },
      },
    };

    const query = useQuery<ICompany[], Error>({
      queryKey: ['listCompaniesByProductId', id],
      queryFn: async (): Promise<ICompany[]> => {
        const response = await this.amplifyApiService.get<{ companies: ICompany[] }>({
          config: amplifyApiConfig,
        });

        return response.companies;
      },
    });

    return query;
  }

  importCompanies({
    onMutate,
    onSuccess,
    onError,
  }: RQMutationSideEffectProps): UseMutationResult<void, Error, UploadCompaniesProps> {
    const mutation = useMutation<void, Error, UploadCompaniesProps>({
      mutationFn: async ({ file }: UploadCompaniesProps) => {
        const filename = file.name;

        const signedUrl = await this.importFileService.getSignedUrl({
          filename,
          type: FileImportType.IMPORT_COMPANIES,
        });

        await this.importFileService.uploadFile({
          file,
          signedUrl,
        });
      },
      onMutate,
      onSuccess,
      onError,
    });

    return mutation;
  }

  removeProductCompany = (
    options: UseMutationOptions<void, Error, RemoveProductCompanyProps>,
  ): UseMutationResult<void, Error, RemoveProductCompanyProps> => {
    const mutation = useMutation<void, Error, RemoveProductCompanyProps>({
      mutationFn: async ({ productId, companyId }: RemoveProductCompanyProps) => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `/product-companies/${productId}?company_id=${companyId}`,
          init: {},
        };

        await this.amplifyApiService.delete<undefined, void>({
          config: amplifyApiConfig,
          body: undefined,
        });
      },
      ...options,
    });

    return mutation;
  };

  createCompanyAppClient(
    options: UseMutationOptions<CreateCompanyAppClientResponse, Error, { companyId: string }>,
  ): UseMutationResult<CreateCompanyAppClientResponse, Error, { companyId: string }> {
    return useMutation({
      mutationFn: async ({
        companyId,
      }: CreateCompanyAppClientInput): Promise<CreateCompanyAppClientResponse> => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `/company/${companyId}?action=oauth-client`,
          init: {},
        };

        const response = await this.amplifyApiService.put<
          undefined,
          CreateCompanyAppClientResponse
        >({
          config: amplifyApiConfig,
          body: undefined,
        });

        return response;
      },
      ...options,
    });
  }

  completeCompanyEDCConfigurationFirstStep(
    options: UseMutationOptions<void, Error, { companyId: string }>,
  ): UseMutationResult<void, Error, { companyId: string }> {
    return useMutation({
      mutationFn: async ({ companyId }: { companyId: string }): Promise<void> => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `/company/${companyId}?action=edc-config-first-step`,
          init: {},
        };

        await this.amplifyApiService.put<undefined, void>({
          config: amplifyApiConfig,
          body: undefined,
        });
      },
      ...options,
    });
  }

  fetchCompanyEDCConfiguration(
    companyId: string,
    options: UseQueryOptions<ICompanyEDCConfiguration, Error> = {},
  ): UseQueryResult<ICompanyEDCConfiguration, Error> {
    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: `/company/${companyId}`,
      init: {
        queryStringParameters: {
          action: 'edc-config',
        },
      },
    };

    const query = useQuery<ICompanyEDCConfiguration, Error>({
      queryKey: ['fetchCompanyEDCConfiguration', companyId],
      queryFn: async (): Promise<ICompanyEDCConfiguration> =>
        await this.amplifyApiService.get<ICompanyEDCConfiguration>({
          config: amplifyApiConfig,
        }),
      ...options,
    });

    return query;
  }

  saveCompanyEDCConfiguration(
    options: UseMutationOptions<void, Error, SaveCompanyEDCConfigurationMutationProps>,
  ): UseMutationResult<void, Error, SaveCompanyEDCConfigurationMutationProps> {
    return useMutation({
      mutationFn: async ({
        companyId,
        edcBaseUrl,
        edcClientId,
        edcClientSecret,
        pcfExchangeClientSecretKey,
      }: SaveCompanyEDCConfigurationMutationProps): Promise<void> => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `/company/${companyId}`,
          init: {},
        };

        return await this.amplifyApiService.put<SaveCompanyEDCConfigurationInput, void>({
          config: amplifyApiConfig,
          body: {
            edc_base_url: edcBaseUrl, // eslint-disable-line camelcase
            client_id: edcClientId, // eslint-disable-line camelcase
            client_secret: edcClientSecret, // eslint-disable-line camelcase
            pcf_exchange_client_secret_key: pcfExchangeClientSecretKey, // eslint-disable-line camelcase
          },
        });
      },
      ...options,
    });
  }

  testCompanyEDCConfiguration(
    options: UseMutationOptions<void, Error, TestCompanyEDCConfigurationMutationProps>,
  ): UseMutationResult<void, Error, TestCompanyEDCConfigurationMutationProps> {
    return useMutation({
      mutationFn: async ({
        companyId,
        edcBaseUrl,
        edcClientId,
        edcClientSecret,
      }: TestCompanyEDCConfigurationMutationProps) => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `/company/${companyId}?action=test-edc-config`,
          init: {},
        };

        return await this.amplifyApiService.put<SaveCompanyEDCConfigurationInput, void>({
          config: amplifyApiConfig,
          body: {
            edc_base_url: edcBaseUrl, // eslint-disable-line camelcase
            client_id: edcClientId, // eslint-disable-line camelcase
            client_secret: edcClientSecret, // eslint-disable-line camelcase
          },
        });
      },
      ...options,
    });
  }

  fetchLicenseHistory(
    companyId: string,
    options: UseQueryOptions<LicenseHistoryItem[], Error> = {},
  ): UseQueryResult<LicenseHistoryItem[], Error> {
    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: '/license-history',
      init: {
        queryStringParameters: {
          company_id: companyId, // eslint-disable-line camelcase
        },
      },
    };

    const query = useQuery<LicenseHistoryItem[], Error>({
      queryKey: ['fetchLicenseHistory', companyId],
      queryFn: async (): Promise<LicenseHistoryItem[]> =>
        await this.amplifyApiService.get<LicenseHistoryItem[]>({
          config: amplifyApiConfig,
        }),
      ...options,
    });

    return query;
  }

  updateLicense(
    options: UseMutationOptions<void, Error, UpdateLicenseMutationProps>,
  ): UseMutationResult<void, Error, UpdateLicenseMutationProps> {
    return useMutation({
      mutationFn: async ({ companyId, license }: UpdateLicenseMutationProps) => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `/company/${companyId}`,
          init: {
            queryStringParameters: {
              action: 'update-license',
            },
          },
        };

        return await this.amplifyApiService.put<UpdateLicenseInput, void>({
          config: amplifyApiConfig,
          body: {
            license,
          },
        });
      },
      ...options,
    });
  }
}

export default CompanyService;
