import CustomInputText from '@components/custom-input-text/CustomInputText';
import Logo, { LogoType } from '@components/logo/Logo';
import useAuthContext from '@hooks/useAuthContext';
import { useForm } from '@hooks/useForm';
import useNotificationContext from '@hooks/useNotificationContext';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import LoginSchema from '@shared/form-validation-schemas/LoginSchema';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { useState } from 'react';

import styles from './Login.module.css';

interface LoginFormProps {
  email: string;
  password: string;
}

const Login = (): JSX.Element => {
  const authContext = useAuthContext();
  const { notify } = useNotificationContext();

  const [isLoading, setIsLoading] = useState(false);
  const [staySignedIn, setStaySignedIn] = useState(true);

  const { register, handleSubmit, errors } = useForm<LoginFormProps>({
    schema: LoginSchema,
  });

  const onSubmit = async (data: LoginFormProps): Promise<void> => {
    const { email, password } = data;

    setIsLoading(true);

    try {
      await authContext.loginWithEmail?.(email, password, staySignedIn);
    } catch (err: unknown) {
      // Failed to sign in
      let errorMessage = err as string;

      if (err instanceof Error) {
        errorMessage = err.message;
      }

      notify({
        severity: ToastSeverity.ERROR,
        summary: 'Failed to sign in',
        detail: errorMessage,
        life: 5000,
      });
    }

    setIsLoading(false);
  };

  const onRememberMeChange = (): void => {
    setStaySignedIn(!staySignedIn);
  };

  return (
    <div
      className={classNames(
        'flex flex-column h-screen md:h-screen justify-content-center align-items-center',
        styles['auth-bg'],
      )}
    >
      <div
        className={classNames(
          'flex flex-column border-round-2xl p-6 bg-white align-items-center',
          styles['auth-container'],
        )}
      >
        <div className='w-full'>
          <div className='flex flex-column md:flex-row md:justify-content-between mb-2'>
            <div className={classNames('mb-6 flex align-items-center', styles['two-logos'])}>
              <Logo className={classNames('w-auto', styles['basf-logo'])} type={LogoType.BASF} />
              <Logo
                className={classNames('w-auto', styles['pacific-logo'])}
                type={LogoType.PACIFIC}
              />
            </div>
            <p className='flex flex-row w-full justify-content-end'>ADMIN</p>
          </div>
          <h3 className='mt-0 mb-5'>Sign in</h3>
          <form
            className='flex flex-1 flex-column justify-content-start'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='mb-4'>
              <CustomInputText
                name='email'
                type='text'
                placeholder='Email'
                error={errors.email?.message}
                register={register}
                data-cy='email-input'
              />{' '}
            </div>
            <div className='mb-4'>
              <CustomInputText
                name='password'
                type='password'
                placeholder='Password'
                error={errors.password?.message}
                register={register}
                data-cy='password-input'
              />{' '}
            </div>
            <div className='flex mb-6 align-items-center'>
              <Checkbox
                name='remember'
                className={classNames('mr-2', styles['checkbox'])}
                inputId='remember-me'
                checked={staySignedIn}
                onChange={onRememberMeChange}
              />
              <label htmlFor='remember-me'>Stay signed in</label>
            </div>
            <Button
              label='Sign In'
              type='submit'
              className='w-full'
              disabled={isLoading}
              data-cy='sign-in-button'
            />
          </form>
        </div>
      </div>
      <div className='col-12 flex gap-2 justify-content-center align-items-center my-2'>
        <span className='text-white'>Powered By</span>
        <Logo className='w-auto' type={LogoType.CIRCULARTREE} white />
      </div>
    </div>
  );
};

export default Login;
