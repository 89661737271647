import AppRoute from '@components/app-route/AppRoute';
import Layout from '@components/layout/Layout';
import Login from '@pages/login/Login';
import Companies from '@pages/main/companies/Companies';
import Products from '@pages/main/products/Products';
import Requests from '@pages/main/requests/Requests';
import Users from '@pages/main/users/Users';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export enum RouterPath {
  ROOT = '/',
  LOGIN = '/login',
  REQUESTS = '/requests',
  USERS = '/users',
  COMPANIES = '/companies',
  PRODUCTS = '/products',
  ANY = '*',
}

export const MainRouter = (): JSX.Element => (
  <BrowserRouter>
    <Layout>
      <Routes>
        <Route path={RouterPath.LOGIN} element={<AppRoute redirect={RouterPath.COMPANIES} />} />
        <Route path={RouterPath.ROOT} element={<AppRoute redirect={RouterPath.COMPANIES} />} />

        <Route path={RouterPath.COMPANIES} element={<AppRoute component={Companies} />} />

        <Route path={RouterPath.PRODUCTS} element={<AppRoute component={Products} />} />

        <Route path={RouterPath.USERS} element={<AppRoute component={Users} />} />

        <Route path={RouterPath.REQUESTS} element={<AppRoute component={Requests} />} />

        <Route path={RouterPath.ANY} element={<AppRoute redirect={RouterPath.LOGIN} />} />
      </Routes>
    </Layout>
  </BrowserRouter>
);

export const GuestRouter = (): JSX.Element => (
  <BrowserRouter>
    <Routes>
      <Route path={RouterPath.ROOT} element={<AppRoute redirect={RouterPath.LOGIN} />} />

      <Route path={RouterPath.LOGIN} element={<AppRoute component={Login} />} />

      <Route path={RouterPath.ANY} element={<AppRoute redirect={RouterPath.LOGIN} />} />
    </Routes>
  </BrowserRouter>
);
