import useNotificationContext from '@hooks/useNotificationContext';
import handleNotification from '@shared/helpers/handle-notification';
import { INotification } from '@shared/interfaces/INotification';
import { Auth } from 'aws-amplify';
import { useEffect, useMemo } from 'react';
import useWebsocket, { ReadyState } from 'react-use-websocket';

import WebsocketContext, { IWebsocketContext } from './WebsocketContext';

/**
 * Retrieves the WebSocket URL with the appended idToken, to be used by useWebsocket method.
 * @returns A promise that resolves to the WebSocket URL with the idToken.
 */
const getWebsocketUrl = async (): Promise<string> => {
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

  return process.env.REACT_APP_WEBSOCKET_URL + '?idToken=' + idToken;
};

type WebsocketProviderProps = {
  children: React.ReactNode;
};

const WebsocketProvider = ({ children }: WebsocketProviderProps): JSX.Element => {
  const { lastMessage, lastJsonMessage, readyState } = useWebsocket(getWebsocketUrl, {
    shouldReconnect: () => true,
  });

  const { notify } = useNotificationContext();

  // When the WebSocket connection is open and a message is received, handle the notification
  useEffect(() => {
    if (readyState === ReadyState.OPEN && lastJsonMessage) {
      handleNotification({ notification: lastJsonMessage as INotification, notify });
    }
  }, [lastJsonMessage]);

  const state = useMemo<IWebsocketContext>(
    () => ({ lastMessage, lastJsonMessage, readyState }),
    [lastMessage, lastJsonMessage, readyState],
  );

  return <WebsocketContext.Provider value={state}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProvider;
