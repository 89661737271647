import BASFLogo from '@assets/images/basf-logo.svg';
import CombinedLogoWhite from '@assets/images/basf-logo-white.svg';
import CircularTreeLogoWhite from '@assets/images/circulartree-logo-white.png';
import PacificLogo from '@assets/images/pacific-logo.svg';
import PacificLogoWhite from '@assets/images/pacific-logo-white.png';
import { classNames } from 'primereact/utils';

import styles from './Logo.module.css';

export enum LogoType {
  BASF = 'basf',
  PACIFIC = 'pacific',
  COMBINED = 'basf-pacific',
  CIRCULARTREE = 'circulartree',
}

type LogoProps = {
  className?: string;
  type?: LogoType;
  white?: boolean;
};

const logoSrcMap = {
  [LogoType.PACIFIC]: PacificLogo,
  [`${LogoType.PACIFIC}-white`]: PacificLogoWhite,
  [LogoType.BASF]: BASFLogo,
  [`${LogoType.BASF}-white`]: '',
  [LogoType.COMBINED]: '',
  [`${LogoType.COMBINED}-white`]: CombinedLogoWhite,
  [LogoType.CIRCULARTREE]: '',
  [`${LogoType.CIRCULARTREE}-white`]: CircularTreeLogoWhite,
};

const Logo = ({ className, type = LogoType.PACIFIC, white = false }: LogoProps): JSX.Element => (
  <img
    src={logoSrcMap[`${type}${white ? '-white' : ''}`]}
    className={classNames(styles.logo, className)}
  />
);

export default Logo;
