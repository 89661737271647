import { Auth } from 'aws-amplify';

/* eslint-disable camelcase */
const AuthConfig = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  endpoint: process.env.REACT_APP_COGNITO_ENDPOINT || null,
  authenticationFlowType: process.env.REACT_APP_COGNITO_AUTHENTICATIONFLOWTYPE,
};

const ApiConfig = {
  endpoints: [
    {
      name: process.env.REACT_APP_AMPLIFY_API_NAME,
      endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT,
      custom_header: async (): Promise<unknown> => ({
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }),
    },
  ],
};
/* eslint-enable camelcase */

const AmplifyConfig = {
  Auth: AuthConfig,
  API: ApiConfig,
};

export default AmplifyConfig;
