import CompaniesTable from './components/companies-table/CompaniesTable';
import ImportCompanies from './components/import-companies/ImportCompanies';

const Companies = (): JSX.Element => (
  <div className='flex flex-column gap-1 justify-content-start align-items-start w-full'>
    <h1 className='text-2xl md:text-4xl'>Companies</h1>

    <div className='flex flex-column gap-2 align-items-start mb-2 w-full'>
      <ImportCompanies />
    </div>

    <CompaniesTable />
  </div>
);

export default Companies;
