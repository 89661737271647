import { Button } from 'primereact/button';

type LoadingButtonProps = {
  label: string;
};

const LoadingButton = ({ label }: LoadingButtonProps): JSX.Element => (
  <Button label={label} loading icon='pi pi-refresh' className='w-full' />
);

export default LoadingButton;
