import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { MouseEventHandler } from 'react';

type MenuButtonProps = {
  isOpen: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const MenuButton = ({ isOpen, onClick }: MenuButtonProps): JSX.Element => (
  <Button
    size='small'
    icon={classNames('pi', {
      'pi-times': isOpen,
      'pi-bars': !isOpen,
    })}
    className='text-white p-0 w-auto mr-3'
    onClick={onClick}
    pt={{
      icon: { className: 'text-2xl' },
    }}
  />
);
export default MenuButton;
