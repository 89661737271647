import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Dispatch, SetStateAction } from 'react';

import styles from './ProductSearch.module.css';

type ProductSearchProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

const ProductSearch = ({ value, setValue }: ProductSearchProps): JSX.Element => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  return (
    <div className='w-full'>
      <span className={classNames('p-input-icon-left w-full', styles['search-bar'])}>
        <i className='pi pi-search' />
        <InputText
          className='w-full h-full'
          value={value}
          onChange={onChange}
          placeholder='Type to search'
        />
      </span>
    </div>
  );
};

export default ProductSearch;
