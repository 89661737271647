import ProductService from '@services/ProductService';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import styles from './OdFilter.module.css';

type DropdownOption = {
  label: string;
  value: string;
};

type OdFilterProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

const OdFilter = ({ value, setValue }: OdFilterProps): JSX.Element => {
  const [ods, setOds] = useState<string[]>([]);

  const productService = new ProductService();

  // Fetch products
  const { data: products } = productService.listProducts();

  useEffect(() => {
    // Collect ods into an array
    let _ods = products?.map((product) => product.od);

    // Remove duplicates and empty strings
    _ods = _ods?.filter((od, index) => _ods?.indexOf(od) === index && od);

    setOds(_ods || []);
  }, [products]);

  const onChange = (e: DropdownChangeEvent): void => {
    setValue(e.value);
  };

  const options = ods.map((od) => ({ label: od, value: od }));

  const valueTemplate = (option: DropdownOption): JSX.Element => {
    if (option?.label) {
      return (
        <>
          <span className='text-primary'>OD:</span> <span>{option?.label}</span>
        </>
      );
    }
    return <span>OD</span>;
  };

  return (
    <div className={styles['dropdown']}>
      <Dropdown
        className={classNames({
          'min-w-12rem max-w-20rem': value,
          'w-8rem': !value,
        })}
        value={value || null}
        valueTemplate={valueTemplate}
        onChange={onChange}
        options={options}
        placeholder='OD'
        showClear
        pt={{
          input: {
            className: 'text-900',
          },
        }}
      />
    </div>
  );
};

export default OdFilter;
