import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldValues,
  FormState,
  useForm as useFormBase,
  UseFormProps,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form';
import { ObjectSchema } from 'yup';

// Extend the UseFormProps with the schema which is required for yupResolver (for params)
type UseFormHookArgumentType<T extends FieldValues = FieldValues> = UseFormProps<T> & {
  schema: ObjectSchema<T>;
};

// Extend the UseFormReturn with the errors object (for return)
type ReturnType<T extends FieldValues = FieldValues> = UseFormReturn<T, object> & {
  errors: FormState<T>['errors'];
};

// Custom form hook which uses yupResolver, takes UseFormHookArgumentType as a param and returns ReturnType
export function useForm<T extends FieldValues = FieldValues>({
  ...options
}: UseFormHookArgumentType<T>): ReturnType<T> {
  // Use the useFormBase with yupResolver
  const formObject = useFormBase<T>({ ...options, resolver: yupResolver(options.schema) });

  return { ...formObject, errors: formObject.formState.errors };
}

// In case you have your custom Input components, you can use this type to register them
export type RegisterType<T extends FieldValues = FieldValues> = UseFormRegister<T>;
