import CompanyFilter from '@components/company-filter/CompanyFilter';
import { Button } from 'primereact/button';
import { Dispatch, SetStateAction } from 'react';

import OdFilter from '../od-filter/OdFilter';
import ProductSearch from '../product-search/ProductSearch';
import styles from './ProductsTableHeader.module.css';

type ProductsTableHeaderProps = {
  refetchProducts: () => void;
  isProductsFetching: boolean;
  companyId: string;
  setCompanyId: Dispatch<SetStateAction<string>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  od: string;
  setOd: Dispatch<SetStateAction<string>>;
};

const ProductsTableHeader = ({
  refetchProducts,
  isProductsFetching,
  companyId,
  setCompanyId,
  search,
  setSearch,
  od,
  setOd,
}: ProductsTableHeaderProps): JSX.Element => (
  <div className='flex flex-column md:flex-row gap-3 w-full align-items-center md:justify-content-end'>
    <div className='flex md:flex-row align-items-center gap-3 justify-content-between w-full md:w-auto md:justify-content-end'>
      <span className='font-bold'>Filters:</span>
      <div className='flex gap-3'>
        <CompanyFilter value={companyId} setValue={setCompanyId} />
        <OdFilter value={od} setValue={setOd} />
      </div>
    </div>
    <div className='flex gap-3 align-items-center w-full md:w-25rem'>
      <ProductSearch value={search} setValue={setSearch} />
      <Button
        icon='pi pi-refresh'
        rounded
        onClick={refetchProducts}
        loading={isProductsFetching}
        className={styles['refresh-button']}
      />
    </div>
  </div>
);

export default ProductsTableHeader;
