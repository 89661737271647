/* eslint-disable camelcase */

import FailedToGet from '@components/failed-to-get/FailedToGet';
import UserService from '@services/UserService';
import { IUser } from '@shared/interfaces/IUser';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { useState } from 'react';

import ResendInviteButton from './components/ResendInviteButton';
import UsersTableHeader from './components/users-table-header/UsersTableHeader';

const UsersTable = (): JSX.Element => {
  const [companyId, setCompanyId] = useState<string>('');

  const userService = new UserService();

  // RQ Query to get users
  const {
    isLoading: isGetUsersLoading,
    isFetching: isUsersFetching,
    isError: isGetUsersError,
    error: getUsersError,
    data: users,
    refetch: refetchUsers,
  } = userService.listUsers({
    company_id: companyId,
  });

  const statusTemplate = (rowData: IUser): JSX.Element => (
    <div
      className={classNames('border-round-xl text-center', {
        'bg-secondary': rowData.status === 'active',
        'bg-primary': rowData.status === 'new',
        'bg-red-500 text-white': rowData.status === 'inactive',
      })}
    >
      {rowData.status}
    </div>
  );

  const resendInviteTemplate = (rowData: IUser): JSX.Element | null =>
    rowData.status === 'new' ? (
      <ResendInviteButton userId={rowData.id} lastInvitedAt={rowData.last_invited_at} />
    ) : null;

  const roleTemplate = (rowData: IUser): JSX.Element => (
    <span>{rowData.role ? (rowData.role === 'company-admin' ? 'Company Admin' : 'User') : ''}</span>
  );

  if (isGetUsersError) {
    return <FailedToGet label='companies' reason={getUsersError.message} />;
  }

  return (
    <div className='w-full'>
      <DataTable
        value={users}
        showGridlines
        sortField='email'
        sortOrder={1}
        size='small'
        header={
          <UsersTableHeader
            refetchUsers={refetchUsers}
            isUsersFetching={isUsersFetching}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        }
        loading={isGetUsersLoading}
      >
        <Column field='company.company_id' sortable header='Company ID' className='w-1' />
        <Column field='company.company_name' sortable header='Company Name' />
        <Column field='first_name' sortable header='First Name' />
        <Column field='last_name' sortable header='Last Name' />
        <Column field='email' sortable header='Email' />
        <Column field='role' sortable header='Role' body={roleTemplate} />
        <Column field='language' sortable header='Language' className='w-3rem' />
        <Column field='status' body={statusTemplate} sortable header='Status' className='w-3rem' />
        <Column body={resendInviteTemplate} className='text-center' />
      </DataTable>
    </div>
  );
};

export default UsersTable;
/* eslint-enable camelcase */
