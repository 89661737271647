import CompanyFilter from '@components/company-filter/CompanyFilter';
import { Button } from 'primereact/button';
import { Dispatch, SetStateAction } from 'react';

type UsersTableHeaderProps = {
  refetchUsers: () => void;
  isUsersFetching: boolean;
  companyId: string;
  setCompanyId: Dispatch<SetStateAction<string>>;
};

const UsersTableHeader = ({
  refetchUsers,
  isUsersFetching,
  companyId,
  setCompanyId,
}: UsersTableHeaderProps): JSX.Element => (
  <div className='flex flex-row gap-3 w-full justify-content-between md:justify-content-end align-items-center'>
    <span className='font-bold'>Filters:</span>

    <div className='flex gap-3'>
      <CompanyFilter value={companyId} setValue={setCompanyId} />
      <Button icon='pi pi-refresh' rounded onClick={refetchUsers} loading={isUsersFetching} />
    </div>
  </div>
);

export default UsersTableHeader;
