/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore

export function CommentIcon(props: React.SVGProps): JSX.Element {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.04 1.30917C5.01344 1.0195 7.00541 0.874393 9 0.875002C11.025 0.875002 13.0142 1.02334 14.96 1.30834C16.6083 1.55167 17.75 2.995 17.75 4.61667V9.63334C17.75 11.255 16.6083 12.6983 14.96 12.9417C13.9986 13.0826 13.0324 13.189 12.0633 13.2608C12.014 13.2641 11.9661 13.2785 11.9232 13.3031C11.8803 13.3276 11.8436 13.3616 11.8158 13.4025L9.52 16.8467C9.46292 16.9323 9.3856 17.0025 9.29489 17.051C9.20417 17.0995 9.10288 17.1249 9 17.1249C8.89712 17.1249 8.79583 17.0995 8.70511 17.051C8.6144 17.0025 8.53708 16.9323 8.48 16.8467L6.18417 13.4025C6.1564 13.3616 6.11967 13.3276 6.07678 13.3031C6.0339 13.2785 5.98598 13.2641 5.93667 13.2608C4.9676 13.1887 4.00143 13.082 3.04 12.9408C1.39167 12.6992 0.25 11.2542 0.25 9.6325V4.6175C0.25 2.99584 1.39167 1.55084 3.04 1.30917ZM4.625 5.875C4.625 5.70924 4.69085 5.55027 4.80806 5.43306C4.92527 5.31585 5.08424 5.25 5.25 5.25H12.75C12.9158 5.25 13.0747 5.31585 13.1919 5.43306C13.3092 5.55027 13.375 5.70924 13.375 5.875C13.375 6.04076 13.3092 6.19973 13.1919 6.31694C13.0747 6.43415 12.9158 6.5 12.75 6.5H5.25C5.08424 6.5 4.92527 6.43415 4.80806 6.31694C4.69085 6.19973 4.625 6.04076 4.625 5.875ZM5.25 7.75C5.08424 7.75 4.92527 7.81585 4.80806 7.93306C4.69085 8.05027 4.625 8.20924 4.625 8.375C4.625 8.54076 4.69085 8.69973 4.80806 8.81694C4.92527 8.93415 5.08424 9 5.25 9H9C9.16576 9 9.32473 8.93415 9.44194 8.81694C9.55915 8.69973 9.625 8.54076 9.625 8.375C9.625 8.20924 9.55915 8.05027 9.44194 7.93306C9.32473 7.81585 9.16576 7.75 9 7.75H5.25Z'
        fill={props?.fill || '#B0BBC5'}
      />
    </svg>
  );
}
