import { useState } from 'react';

type CustomIconProps = {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconOptions?: React.SVGProps<SVGSVGElement>;
  className?: string;
  color?: string;
  hoverColor?: string;
  onClick?: () => void;
};

const CustomIcon = ({
  icon,
  iconOptions,
  className,
  color = '#B0BBC5',
  hoverColor,
  onClick,
}: CustomIconProps): JSX.Element => {
  const Icon = icon;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnter = (): void => {
    setIsHovered(true);
  };

  const onMouseLeave = (): void => {
    setIsHovered(false);
  };

  return (
    <i
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role={onClick ? 'button' : undefined}
      onClick={onClick}
    >
      <Icon {...iconOptions} fill={isHovered && hoverColor ? hoverColor : color} />
    </i>
  );
};

export default CustomIcon;
