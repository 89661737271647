import { createContext } from 'react';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';

export type IWebsocketContext = Pick<
  WebSocketHook,
  'lastMessage' | 'lastJsonMessage' | 'readyState'
>;

const WebsocketContext = createContext({} as IWebsocketContext);

export default WebsocketContext;
