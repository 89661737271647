import { Tag } from 'primereact/tag';

import styles from './ProductName.module.css';

type ProductNameProps = {
  productName?: string;
  requestedProductName?: string;
};

const ProductName = ({
  productName,
  requestedProductName,
}: ProductNameProps): JSX.Element | null => {
  if (!productName) {
    return null;
  }

  const showRequestedProductName = requestedProductName && requestedProductName !== productName;

  return (
    <div className='flex flex-column relative'>
      <div>
        <Tag
          className='text-base font-normal '
          value={productName}
          pt={{
            root: {
              className: styles['product-name-tag'],
            },
            value: {
              className: styles['product-name-tag-value'],
            },
          }}
        />
      </div>
      {showRequestedProductName && (
        <div className='absolute top-100'>
          <Tag
            className='text-gray-500 text-sm font-light'
            value={requestedProductName}
            pt={{
              root: {
                className: styles['requested-product-name-tag'],
              },
              value: {
                className: styles['requested-product-name-tag-value'],
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductName;
